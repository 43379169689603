<template>
  <div class="main">
    <div class="container">
      <van-nav-bar :title="data.post_title"
                   left-arrow
                   @click-left="onClickLeft" />
      <div class="content"
           v-html="data.post_content">
      </div>
    </div>
    <DjhrqFooter />
  </div>
</template>

<script>
import DjhrqFooter from "@/components/footer"
export default {
  name: 'DjhrqDetails',
  data () {
    return {
      data: {}
    };
  },
  created () {
    if (this.$route.query.code) {
      this.getDetails(this.$route.query.code)
    } else {
      this.$router.push({ name: "list" });
    }
  },
  components: {
    DjhrqFooter
  },
  methods: {
    onClickLeft () { 
      this.$router.back()
    },
    getDetails (code) {
      let that = this
      that.$axios.get('/api/portal/articles/api_obtain/code/' + code).then(res => {
        if (res.data.data) {
          that.data = res.data.data
          that.data.post_content = that.escapeHtml(res.data.data.post_content)
        }
        // 数据全部加载完成
      }).catch((error) => {
        console.log(error);
      });
    },
    // 处理方法
    escapeHtml (str) {
      var arrEntities = {
        'lt': '<',
        'gt': '>',
        'nbsp': ' ',
        'amp': '&',
        'quot': '"'
      };
      return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) {
        return arrEntities[t];
      });
    },

  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 20px;
  font-size: 14px;
  line-height: 24px;
}
</style>